<template>
  <div class="water" :style="`width:${width1}px;height:${width1}px`">
    <div class="point point1" :style="`width:${width2}px;height:${width2}px`"></div>
    <div class="point point2" :style="`width:${width2}px;height:${width2}px`"></div>
    <div class="point point3" :style="`width:${width2}px;height:${width2}px`"></div>
    <div class="point point4" :style="`width:${width2}px;height:${width2}px`"></div>
  </div>
</template>
<script>
export default {
  props: {
    width1: {
      type: Number,
      default: 36,
    },
    width2: {
      type: Number,
      default: 16,
    },
  },
};
</script>
<style scoped>
.water {
  width: 32px;
  height: 32px;
  position: relative;
}
.point {
  z-index: -1;
  width: 16px;
  height: 16px;
}
.water .point {
  position: absolute;
  border-radius: 50%;
  animation: border 2s linear infinite;
}

.water .point2 {
  -webkit-animation-delay: 0.5s;
}

.water .point3 {
  -webkit-animation-delay: 1s;
}

.water .point4 {
  -webkit-animation-delay: 1.5s;
}

@keyframes border {
  from {
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    background-color: rgba(11, 124, 255, 0.5);
  }

  to {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(235, 51, 36, 0);
  }
}
</style>