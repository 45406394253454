<template>
  <div class="symposium-main">
    <!-- 第一屏 -->
    <div class="symposium-top">
      <div class="title">{{ $t("symposium.page1.title1") }}</div>
      <div class="tips">
        {{ $t("symposium.page1.tips1") }}
      </div>
      <div class="tips">
        {{ $t("symposium.page1.tips2") }}
      </div>
    </div>
    <!-- 第二屏 -->
    <div class="label-boxs">
      <div class="labels" v-for="item in 4" :key="item">
        <img :src="require(`@/assets/symposium/label${item}.png`)" />
        <div class="title">{{ $t(`symposium.page2.title${item}`) }}</div>
      </div>
    </div>
    <!-- 访谈 -->
    <div class="interview">
      <div class="interview-bg"></div>
      <div class="interview-box">
        <div class="left">
          <div class="title-box">
            <div class="circle-box">
              <div class="ripple-box">
                <Ripple :width1="width1" :width2="width2"></Ripple>
              </div>
              <div class="circle"></div>
            </div>
            <span>{{ $t("symposium.page3.title1") }}</span>
          </div>
          <div class="tips-box">{{ $t("symposium.page3.tips1") }}</div>
          <img class="left1" src="@/assets/symposium/left1.png" />
          <img class="left2" src="@/assets/symposium/left2.png" />
          <img class="left3" src="@/assets/symposium/left3.png" />
        </div>
        <div class="right">
          <img class="right1" src="@/assets/symposium/right1.png" />
          <div class="title-box">
            <div class="circle-box">
              <div class="ripple-box">
                <Ripple :width1="width1" :width2="width2"></Ripple>
              </div>
              <div class="circle"></div>
            </div>
            <span>{{ $t("symposium.page3.title2") }}</span>
          </div>
          <div class="tips-box">{{ $t("symposium.page3.tips2") }}</div>
          <img class="right2" src="@/assets/symposium/right2.png" />
        </div>
      </div>
    </div>
    <!-- 部分商业案例 -->
    <div class="business-case">
      <div class="title">{{ $t("symposium.page4.title1") }}</div>

      <div class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in 5" :key="item">
            <img class="brand" src="@/assets/symposium/brand.png" />
            <div class="title-box">{{ $t("symposium.page4.title2") }}</div>
            <div class="tips">{{ $t("symposium.page4.title3") }}</div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="business-case2">
      <div class="title">{{ $t("symposium.page4.title1") }}</div>
      <div class="business-box" v-for="item in 5" :key="item">
        <img class="brand" src="@/assets/symposium/brand.png" />
        <div class="title-box">{{ $t("symposium.page4.title2") }}</div>
        <div class="tips">{{ $t("symposium.page4.title3") }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Ripple from "@/components/Ripple2";
export default {
  components: { Ripple },
  data() {
    return {
      width1: 20,
      width2: 18,
    };
  },
  mounted() {
    new Swiper(".mySwiper", {
      slidesPerView: 4,
      spaceBetween: 60,
    });
  },
};
</script>

<style lang="scss" scoped>
.symposium-main {
  // 第一屏
  .symposium-top {
    width: 100%;
    height: 348px;
    background-color: #000;
    position: relative;
    background-image: url("../assets/symposium/bg1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .title {
      font-size: 24px;
      font-weight: bolder;
      padding-top: 142px;
      margin-left: 120px;
      margin-bottom: 27px;
      color: #fff;
    }
    .tips {
      color: #fff;
      margin-left: 120px;
      margin-bottom: 16px;
      font-size: 18px;
      padding-right: 20px;
    }
  }
  // 第二屏
  .label-boxs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: calc(100% - 240px);
    margin-left: 120px;
    padding: 20px 0;
    flex-wrap: wrap;
    .labels {
      width: 287px;
      height: 186px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 76px;
        height: 76px;
        margin-top: 29px;
      }
      .title {
        font-size: 18px;
        color: #232323;
      }
    }
  }
  //访谈
  .interview {
    width: 100%;
    // background: #f7fbff;
    margin-top: 60px;
    position: relative;
    .interview-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      background: #f7fbff;
      z-index: 9;
    }
    .interview-box {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 240px);
      margin-left: 120px;
      position: relative;
      z-index: 99;
      .left {
        width: calc(50% - 111px);
        flex-shrink: 0;
        .title-box {
          display: flex;
          align-items: center;
          margin-top: 80px;
          .circle-box {
            width: 20px;
            height: 20px;
            position: relative;
            margin-right: 22px;
            .ripple-box {
              position: absolute;
            }
            .circle {
              position: absolute;
              left: 5px;
              top: 5px;
              width: 10px;
              height: 10px;
              background: #0b7cff;
              opacity: 1;
              border-radius: 5px;
            }
          }
          span {
            font-size: 26px;
            font-weight: bolder;
            color: #232323;
          }
        }
        .tips-box {
          width: 500px;
          color: #666;
          font-size: 18px;
          margin-top: 50px;
        }
        .left1 {
          width: 388px;
          height: 388px;
          margin-top: 60px;
          display: block;
        }
        .left2 {
          display: block;
          width: 132px;
          height: 132px;
          margin-top: 13px;
          margin-left: 101px;
        }
        .left3 {
          width: 31px;
          margin-top: 43px;
          margin-bottom: 95px;
        }
      }
      .right {
        width: calc(50% - 111px);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .right1 {
          width: 65px;
          height: 82px;
          margin-top: 54px;
        }
        .title-box {
          display: flex;
          align-items: center;
          margin-top: 80px;
          width: 100%;
          text-align: left;
          .circle-box {
            width: 20px;
            height: 20px;
            position: relative;
            margin-right: 22px;
            .ripple-box {
              position: absolute;
            }
            .circle {
              position: absolute;
              left: 5px;
              top: 5px;
              width: 10px;
              height: 10px;
              background: #0b7cff;
              opacity: 1;
              border-radius: 5px;
            }
          }
          span {
            font-size: 26px;
            font-weight: bolder;
            color: #232323;
          }
        }
        .tips-box {
          width: 500px;
          color: #666;
          font-size: 18px;
          margin-top: 50px;
        }
        .right2 {
          width: 388px;
          height: 388px;
          margin-top: 60px;
        }
      }
    }
  }
  //部分商业案例
  .business-case {
    .title {
      text-align: center;
      font-size: 26px;
      color: #232323;
      font-weight: bolder;
      margin-top: 100px;
    }
  }
  .business-case2 {
    display: none;
  }
}

.swiper {
  width: calc(100% - 40px);
  height: 100%;
  overflow: hidden;
  margin-top: 101px;
  margin-bottom: 131px;
  padding: 20px 20px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  // height: 339px;
  background: #fff;
  box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.08);
  .brand {
    width: 100%;
    margin-top: 0;
  }
  .title-box {
    font-size: 16px;
    margin-top: 16px;
    text-align: left;
    font-weight: bolder;
    margin-left: 21px;
  }
  .tips {
    width: calc(100% - 42px);
    margin-top: 32px;
    margin-bottom: 19px;
    font-size: 14px;
    color: #666666;
    margin-left: 21px;
    text-align: left;
  }
}
</style>
<style lang="scss" scoped>
// 媒体查询

@media only screen and (max-width: 1250px) {
  .symposium-main {
    //访谈
    .interview {
      width: 100%;
      margin-top: 60px;
      position: relative;
      .interview-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #f7fbff;
        z-index: 9;
      }
      .interview-box {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 80px);
        margin-left: 40px;
        position: relative;
        z-index: 99;
        .left {
          width: calc(50% - 20px);
          flex-shrink: 0;
          .title-box {
            display: flex;
            align-items: center;
            margin-top: 80px;
            .circle-box {
              width: 20px;
              height: 20px;
              position: relative;
              margin-right: 22px;
              .ripple-box {
                position: absolute;
              }
              .circle {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 10px;
                height: 10px;
                background: #0b7cff;
                opacity: 1;
                border-radius: 5px;
              }
            }
            span {
              font-size: 26px;
              font-weight: bolder;
              color: #232323;
            }
          }
          .tips-box {
            width: 100%;
            color: #666;
            font-size: 18px;
            margin-top: 50px;
          }
          .left1 {
            width: 388px;
            height: 388px;
            margin-top: 60px;
            display: block;
          }
          .left2 {
            display: block;
            width: 132px;
            height: 132px;
            margin-top: 13px;
            margin-left: 101px;
          }
          .left3 {
            width: 31px;
            margin-top: 43px;
            margin-bottom: 95px;
          }
        }
        .right {
          width: calc(50% - 20px);
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .right1 {
            width: 65px;
            height: 82px;
            margin-top: 54px;
          }
          .title-box {
            display: flex;
            align-items: center;
            margin-top: 80px;
            width: 100%;
            text-align: left;
            .circle-box {
              width: 20px;
              height: 20px;
              position: relative;
              margin-right: 22px;
              .ripple-box {
                position: absolute;
              }
              .circle {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 10px;
                height: 10px;
                background: #0b7cff;
                opacity: 1;
                border-radius: 5px;
              }
            }
            span {
              font-size: 26px;
              font-weight: bolder;
              color: #232323;
            }
          }
          .tips-box {
            width: 100%;
            color: #666;
            font-size: 18px;
            margin-top: 50px;
          }
          .right2 {
            width: 388px;
            height: 388px;
            margin-top: 60px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .symposium-main {
    // 第一屏
    .symposium-top {
      .title {
        margin-left: 40px;
      }
      .tips {
        margin-left: 40px;
        padding-right: 20px;
      }
    }
    // 第二屏
    .label-boxs {
      width: calc(100% - 80px);
      margin-left: 40px;
    }
    //访谈
    .interview {
      width: 100%;
      margin-top: 60px;
      position: relative;
      .interview-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #f7fbff;
        z-index: 9;
      }
      .interview-box {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 80px);
        margin-left: 0;
        padding: 0 40px;
        position: relative;
        z-index: 99;
        flex-wrap: wrap;
        .left {
          width: 100%;
          flex-shrink: 0;
          .title-box {
            display: flex;
            align-items: center;
            margin-top: 60px;
            .circle-box {
              width: 20px;
              height: 20px;
              position: relative;
              margin-right: 22px;
              .ripple-box {
                position: absolute;
              }
              .circle {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 10px;
                height: 10px;
                background: #0b7cff;
                opacity: 1;
                border-radius: 5px;
              }
            }
            span {
              font-size: 26px;
              font-weight: bolder;
              color: #232323;
            }
          }
          .tips-box {
            width: 100%;
            color: #666;
            font-size: 18px;
            margin-top: 30px;
          }
          .left1 {
            width: 388px;
            height: 388px;
            margin-top: 20px;
            display: block;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
          .left2 {
            display: block;
            width: 132px;
            height: 132px;
            margin-top: 13px;
            margin-left: 101px;
          }
          .left3 {
            width: 31px;
            margin-top: 43px;
            margin-bottom: 95px;
          }
        }
        .right {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding-bottom: 500px;
          .right1 {
            width: 65px;
            height: 82px;
            margin-top: 0px;
          }
          .title-box {
            display: flex;
            align-items: center;
            margin-top: 20px;
            width: 100%;
            text-align: left;
            .circle-box {
              width: 20px;
              height: 20px;
              position: relative;
              margin-right: 22px;
              .ripple-box {
                position: absolute;
              }
              .circle {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 10px;
                height: 10px;
                background: #0b7cff;
                opacity: 1;
                border-radius: 5px;
              }
            }
            span {
              font-size: 26px;
              font-weight: bolder;
              color: #232323;
            }
          }
          .tips-box {
            width: 100%;
            color: #666;
            font-size: 18px;
            margin-top: 30px;
          }
          .right2 {
            width: 388px;
            height: 388px;
            margin-top: 30px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 50px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .symposium-main {
    // 第一屏
    .symposium-top {
      .title {
        margin-left: 0;
        padding-top: 100px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 18px;
      }
      .tips {
        margin-left: 0px;
        font-size: 14px;
        padding: 0 10px;
      }
    }
    // 第二屏
    .label-boxs {
      width: calc(100% - 40px);
      margin-left: 20px;
      .labels {
        width: 287px;
        height: 186px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 76px;
          height: 76px;
          margin-top: 29px;
        }
        .title {
          font-size: 18px;
          color: #232323;
        }
      }
    }
    //访谈
    .interview {
      .interview-box {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 40px);
        margin-left: 0;
        padding: 0 20px;
        position: relative;
        z-index: 99;
        flex-wrap: wrap;
        .left {
          width: 100%;
          flex-shrink: 0;
          .title-box {
            display: flex;
            align-items: center;
            margin-top: 60px;
            .circle-box {
              width: 20px;
              height: 20px;
              position: relative;
              margin-right: 22px;
              .ripple-box {
                position: absolute;
              }
              .circle {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 10px;
                height: 10px;
                background: #0b7cff;
                opacity: 1;
                border-radius: 5px;
              }
            }
            span {
              font-size: 20px;
              font-weight: bolder;
              color: #232323;
            }
          }
          .tips-box {
            width: 100%;
            color: #666;
            font-size: 14px;
            margin-top: 30px;
          }
          .left1 {
            width: 240px;
            height: 240px;
            margin-top: 20px;
            display: block;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
          .left2 {
            display: block;
            width: 100px;
            height: 100px;
            margin-top: 13px;
            margin-left: 101px;
          }
          .left3 {
            width: 30px;
            margin-top: 43px;
            margin-bottom: 95px;
          }
        }
        .right {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding-bottom: 350px;
          .right1 {
            width: 65px;
            height: 82px;
            margin-top: 0px;
          }
          .title-box {
            display: flex;
            align-items: center;
            margin-top: 20px;
            width: 100%;
            text-align: left;
            .circle-box {
              width: 20px;
              height: 20px;
              position: relative;
              margin-right: 22px;
              .ripple-box {
                position: absolute;
              }
              .circle {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 10px;
                height: 10px;
                background: #0b7cff;
                opacity: 1;
                border-radius: 5px;
              }
            }
            span {
              font-size: 20px;
              font-weight: bolder;
              color: #232323;
            }
          }
          .tips-box {
            width: 100%;
            color: #666;
            font-size: 14px;
            margin-top: 30px;
          }
          .right2 {
            width: 240px;
            height: 240px;
            margin-top: 30px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 50px;
          }
        }
      }
    }
    .business-case {
      display: none;
    }
    .business-case2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f7fbff;
      .title {
        font-size: 20px;
        font-weight: bolder;
        margin-top: 50px;
      }
      .business-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        .brand {
          width: calc(100% - 80px);
          margin-left: 40px;
        }
        .title-box {
          font-size: 16px;
          padding: 0 40px;
          margin-top: 20px;
          font-weight: bolder;
          color: #232323;
        }
        .tips {
          font-size: 14px;
          padding: 0 40px;
          color: #666666;
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
@media only screen and (max-width: 614px) {
  .symposium-main {
    // 第二屏
    .label-boxs {
      width: 100%;
      margin-left: 0;
      justify-content: center;
    }
  }
}
</style>